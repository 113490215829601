import axios from 'axios';
import {v4 as uuidv4} from 'uuid';

var ApplicationType = require('./../enums/applicationType');
const applicationVersion = 'v1.0';
window.baseURL = process.env.REACT_APP_BACKEND_URL;

var axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  json: true
});

axiosInstance.interceptors.request.use((config) => {
  if (!window.sessionStorage.getItem('Correlation')) {
    window.sessionStorage.setItem('Correlation', uuidv4());
  }
  config.headers['X-CORRELATION-ID'] = window.sessionStorage.getItem('Correlation');
  config.headers['ApplicationType'] = ApplicationType.default.Web;
  config.headers['ApplicationInformation'] = JSON.stringify({'ApplicationVersion': applicationVersion});

  return config;
});

export default axiosInstance;
