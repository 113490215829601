import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Swal from 'sweetalert2';
import {AccountServices} from '../../../services';

const Home = () => {
	const {t, i18n} = useTranslation();
	const history = useHistory();
	const query = new URLSearchParams(location.search);
	const notme = query.get('notMe');
	const accountverification = query.get('v');
	const paymentStatus = query.get('paymentStatus');
	const statusMessage = query.get('statusMessage');
	const [licenseType, setLicenseType] = useState('monthly');
	
	useEffect(() => {
		console.log(query);
	}, []);
	
	const isAuthenticated = useSelector(state => state.main.isAuthenticated);
	
	useEffect(() => {
		const browserLanguage = navigator.language || navigator.languages[0];
		if (browserLanguage) {
			i18n.changeLanguage(browserLanguage.substring(0, 2)); // İlk iki karakterini alır, örneğin 'en-US' -> 'en'
		}
		
		if (accountverification) {
			let accontVerificationUrl = '/account/company-approval?v=' + accountverification + '' + (notme !== null ? '&notMe=' + notme : '');
			let promise = AccountServices.verifyAccount(accontVerificationUrl);
			promise.then((res) => {
				if (!res && notme === false) {
					let timerInterval;
					Swal.fire({
						icon: 'success',
						title: t('success'),
						timer: 4000,
						timerProgressBar: true,
						showCloseButton: false,
						showConfirmButton: false,
						showDenyButton: false,
						text: 'Kurumsal Hesaba Geçişiniz Onaylanmıştır.',
						willClose: () => {
							clearInterval(timerInterval);
						}
					}).then((result) => {
						if (result.dismiss === Swal.DismissReason.timer) {
							history.push('/login');
						}
					});
				}
			}).catch((e) => {
				console.log(e);
			});
		}
		if (paymentStatus) {
			if (paymentStatus === 'failure') {
				Swal.fire({
					icon: 'error',
					title: t('error'),
					text: statusMessage,
					confirmButtonText: t('ok')
				}).then((result) => {
					if (result.isConfirmed) {
						window.history.replaceState(null, null, '/');
					}
				});
			} else if (paymentStatus === 'success') {
				window.history.replaceState(null, null, '/');
				Swal.fire({
					icon: 'success',
					title: t('success'),
					text: t('packageStatusOK'),
					confirmButtonText: t('ok')
				}).then(() => null);
			}
		}
		
		// isAuthenticated durumuna göre yönlendirme
		
	}, [accountverification, notme, paymentStatus, statusMessage, isAuthenticated, history, i18n, t]);
	
	return (
		<div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
			<div id="hero">
				{/* Hero component goes here */}
			</div>
		</div>
	);
};

export default Home;
