/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {useFormik} from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {UserServices} from 'services';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {Tooltip} from '@mui/material';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {JSEncrypt} from 'jsencrypt';

const MySwal = withReactContent(Swal);
const ChangePassword = ({ onSuccess = () => null, isLogin = false }) => {
  const { t } = useTranslation();
  const validationSchema = yup.object({
    usedPassword: yup
      .string()
      .min(8, t('validPasswordMin'))
      .required(t('validPassword'))
      /* eslint-disable-next-line */
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
        t('validPasswordRegex'),
      ),
    newPassword: yup
      .string()
      .min(8, t('validPasswordMin'))
      .required(t('validPassword'))
      /* eslint-disable-next-line */
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
        t('validPasswordRegex'),
      ),
    repeatNewPassword: yup
      .string()
      .required(t('validPassword'))
      .oneOf([yup.ref('newPassword'), null], t('registerCheckPassword')),
  });
  const initialValues = {
    usedPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  };
  const onSubmit = (values) => {
    const data = { ...values, isCurrentUser: true };
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_PASSWORD_KEY);
    data.usedPassword = encrypt.encrypt(values.usedPassword);
    data.newPassword = encrypt.encrypt(values.newPassword);
    data.repeatNewPassword = encrypt.encrypt(values.repeatNewPassword);
    const promiseChangePassword = UserServices.changePassword(data);
    promiseChangePassword.then((response) => {
      if (response) {
        MySwal.fire({
          icon: 'success',
          title: t('success'),
          text: t('changePasswordSuccessMessage'),
        }).then(() => {
          onSuccess();
        });
      }
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  let disabled =
    !formik.values.usedPassword ||
    !formik.values.newPassword ||
    !formik.values.repeatNewPassword;
  return (
    <Box>
      <Box marginBottom={7}>
        <Typography
          variant="h5"
          color="text.tertiary"
          sx={{
            fontWeight: 700,
            textAlign: 'start',
          }}
        >
          {t('profileChangePassword')}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          item
          spacing={4}
          display="flex"
          flexDirection="row"
          justifyContent="start"
          xl={isLogin ? 12 : 4}
          md={isLogin ? 12 : 12}
        >
          <Grid item xs={12}>
            <TextField
              label={t('userCurrentPassword')}
              variant="outlined"
              name={'usedPassword'}
              type={'password'}
              fullWidth
              value={formik.values.usedPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.usedPassword &&
                Boolean(formik.errors.usedPassword)
              }
              helperText={
                formik.touched.usedPassword && formik.errors.usedPassword
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Tooltip
              title={
                <div style={{ padding: 5 }}>
                  {t('registerValidationMessage1')}
                  <br />
                  {t('registerValidationMessage2')}
                  <br />
                  {t('registerValidationMessage3')}
                </div>
              }
              placement={'bottom-start'}
            >
              <TextField
                label={t('userNewPassword')}
                variant="outlined"
                name={'newPassword'}
                type={'password'}
                fullWidth
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={
                  formik.touched.newPassword &&
                  Boolean(formik.errors.newPassword)
                }
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
              />
            </Tooltip>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t('userRenewPassword')}
              variant="outlined"
              name={'repeatNewPassword'}
              type={'password'}
              fullWidth
              value={formik.values.repeatNewPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.repeatNewPassword &&
                Boolean(formik.errors.repeatNewPassword)
              }
              helperText={
                formik.touched.repeatNewPassword &&
                formik.errors.repeatNewPassword
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ textAlign: 'end' }} width={'100%'}></Box>

            <Box
              display="flex"
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'end'}
              width={'100%'}
            >
              <Grid container item xs={12} justifyContent={'right'}>
                <Grid item md={8} xs={12} textAlign={'right'}>
                  <Button
                    disabled={disabled}
                    size={'large'}
                    variant={'contained'}
                    type={'submit'}
                    sx={{ minWidth: '100px', width: '40%', maxWidth: '150px' }}
                  >
                    {t('save')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

ChangePassword.propTypes = {
  onSuccess: PropTypes.func,
  isLogin: PropTypes.bool,
};

export default ChangePassword;
