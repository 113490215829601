import {Box} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import Calender from './components/Calender';
import CreateMeeting from './CreateMeeting';
import EditMeeting from './EditMeeting';
import Typography from '@mui/material/Typography';
import {MeetingServices, PricingServices} from '../../../../../services';
import {useTranslation} from 'react-i18next';
import PlanMeeting from './PlanMeeting';

function Meetings() {
	const {t} = useTranslation();
	const location = useLocation();
	const [meetings, setMeetings] = useState([]);
	const [calendarLoading, setCalendarLoading] = useState(false);
	const [createModalVisible, setCreateModalVisible] = useState(false);
	const [planModalVisible, setPlanModalVisible] = useState(false);
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [userCurrentProduct, setUserCurrentProduct] = useState({});
	const [selectedMeeting, setSelectedMeeting] = useState({});
	
	useEffect(() => {
		getMeetings();
		getCurrentProducts();
		setTimeout(() => {
			if (location.search.includes('createNow')) {
				setCreateModalVisible(true);
			}
		}, 500);
	}, []);
	
	function getMeetings() {
		setCalendarLoading(false);
		let promise = MeetingServices.getMeetings({
			'pageNumber': 1, 'rowCount': 10, 'sortDirection': 1, 'sortColumn': ''
		});
		
		promise.then(responsive => {
			if (responsive && responsive.data && responsive.data.result && responsive.data.result.items.length > 0) {
				let meetings = responsive.data.result.items;
				
				const meetingArr = meetings.map((item) => {
					
					let meeting = {
						calendarId: '1',
						category: 'time',
						isVisible: item.isActive,
						title: item.name,
						id: item.id,
						body: item.description,
						start: new Date(item.startingDate),
						end: new Date(item.endDate),
						token: item.meetingToken,
						meetingId: item.meetingId,
						meetingUsers: item.meetingUsers,
						creatorFullName: item.creatorFullName,
						creatorId: item.creatorId,
						meetingStatus: item.meetingStatus
					};
					
					return meeting;
				});
				setMeetings(meetingArr);
			}
			setCalendarLoading(true);
		});
	}
	
	function getCurrentProducts() {
		var promise = PricingServices.getCurrentProduct();
		promise.then(response => {
			setUserCurrentProduct(response.data);
		});
	}
	
	return (<div>
			<Box marginBottom={{xs: 1, md: 5}}>
				<Typography
					variant="h5"
					color="text.tertiary"
					sx={{
						fontWeight: 700, textAlign: 'start'
					}}
				>
					{t('meetings')}
				</Typography>
			</Box>
			{calendarLoading && <Calender
				getMeetings={getMeetings}
				selectedMeeting={selectedMeeting}
				setSelectedMeeting={setSelectedMeeting}
				meetings={meetings}
				setEditModalVisible={setEditModalVisible}
				setCreateModalVisible={setCreateModalVisible}
				setPlanModalVisible={setPlanModalVisible}
			/>}
		
			<CreateMeeting
				getMeetings={getMeetings}
				visible={createModalVisible}
				setVisible={setCreateModalVisible}
			/>
			
			<PlanMeeting
				getMeetings={getMeetings}
				visible={planModalVisible}
				setVisible={setPlanModalVisible}
				userCurrentProduct={userCurrentProduct}
			/>
			
			<EditMeeting
				selectedMeeting={selectedMeeting}
				visible={editModalVisible}
				setVisible={setEditModalVisible}
				getMeetings={getMeetings}
			/>
		
		</div>);
}

export default Meetings;
