import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const EditUserDialog = ({ open, onClose, user, onSave }) => {
    const { t } = useTranslation();
    const [editedUser, setEditedUser] = useState({});

    // On component mount or user prop change, set initial values for the form
    useEffect(() => {
        if (user) {
            setEditedUser({
                id: user.id, // id'yi de alıyoruz
                email: user.email || '',
                phoneNumber: user.phoneNumber || '',
                fullName: user.fullName || '',
            });
        }
    }, [user]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedUser({
            ...editedUser,
            [name]: value,
        });
    };

    const handleSave = () => {
        // editedUser'ın içeriğini konsola yazdırarak kontrol edin
        console.log(editedUser);

        onSave(editedUser); // editedUser'ı parent component'e gönderiyoruz
        onClose(); // Dialogu kapatıyoruz
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('edit')}</DialogTitle>
            <DialogContent>
                <TextField
                    label={t('fullName')}
                    name="fullName"
                    value={editedUser.fullName}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />

                <TextField
                    label={t('email')}
                    name="email"
                    value={editedUser.email}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />

                <TextField
                    label={t('phone')}
                    name="phoneNumber"
                    value={editedUser.phoneNumber}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('cancel')}
                </Button>
                <Button onClick={handleSave} color="primary">
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// PropTypes validation
EditUserDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.shape({
        email: PropTypes.string,
        phoneNumber: PropTypes.string,
        fullName: PropTypes.string,
        id: PropTypes.number,
    }),
    onSave: PropTypes.func.isRequired,
};

export default EditUserDialog;
