import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {Grid} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import personIcon from '../../../../../svg/icons/person.svg';
import {useDispatch, useSelector} from 'react-redux';
import {setOnlineUsers, setOutGoingCall, setOutGoingUserName} from '../../../../../redux/actions';
import TooltipButton from '../../../../../layouts/Main/components/ButtonWithTooltip/TooltipButton';

const Persons = () => {
	const theme = useTheme();
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const loginUser = useSelector((state) => state.main.user);
	const onlineUsers = useSelector((state) => state.main.onlineUsers);
	const hubConnection = useSelector((state) => state.main.hubConnection);
	const isXS = useMediaQuery(theme.breakpoints.up('xs'), {defaultMatches: true});
	
	useEffect(() => {
		// Eğer hubConnection yoksa veya bağlantı sağlanamıyorsa online kullanıcıları boşalt
		if (!hubConnection || hubConnection.state !== 'Connected') {
			console.warn('Hub connection is not active. Clearing online users.');
			dispatch(setOnlineUsers([]));
		}
	}, [hubConnection, dispatch]);
	
	function callUser(user) {
		if (!hubConnection || hubConnection.state !== 'Connected') {
			console.error('Cannot make a call. Hub connection is not active.');
			return;
		}
		
		hubConnection.invoke('CallToUser', user.email).then((callID) => {
			let outGoingCallModel = {userName: user.fullName, callID: callID};
			console.log('outGoingCallModel:', outGoingCallModel);
			dispatch(setOutGoingCall(outGoingCallModel));
			dispatch(setOutGoingUserName(user.fullName));
		}).catch((error) => {
			console.error('Error during calling user:', error);
		});
	}
	
	return (
		<Box>
			<Box marginBottom={7}>
				<Grid container>
					<Grid item md={8} xs={12}>
						<Typography
							variant="h5"
							color="text.tertiary"
							sx={{
								fontWeight: 700,
								textAlign: 'start'
							}}
						>
							{t('persons')}
						</Typography>
						<small>{t('thisListOnlyOnlineUsers')}</small>
					</Grid>
					
					<Grid
						item
						md={4}
						container
						sx={{justifyContent: 'end'}}
					>
						<TextField
							fullWidth={isXS}
							label={t('search')}
							size={'small'}
							sx={{
								float: 'right',
								width: {xs: '%100', md: 240},
								mr: 1,
								mt: {xs: 3, md: 0}
							}}
							onInput={() => {
								// Implement search logic if needed
							}}
						/>
					</Grid>
				</Grid>
				
				<Grid container sx={{marginTop: 5, borderTop: '1px solid rgba(58,21,161,50%)'}}>
					{onlineUsers.map((item, i) => (
						<Grid
							key={i}
							container
							direction={'row'}
							sx={{
								paddingTop: 2,
								paddingBottom: 2,
								borderBottom: '1px solid rgba(58,21,161,50%)'
							}}
						>
							<Grid item md={9} style={{display: 'flex'}}>
								<Box component={'div'} style={{width: '50px', height: '50px'}}>
									<img
										style={{
											backgroundColor: item.status === 1 ? 'red' : 'white',
											padding: item.status === 1 ? '2px' : '0px',
											borderRadius: item.status === 1 ? '30px' : '0px'
										}}
										src={personIcon}
										width={50}
										height={50}
									/>
								</Box>
								<Box flex={1} component={'div'} marginLeft={'10px'} paddingTop={'4px'}>
									<div>
										<strong>{item.fullName}</strong>
									</div>
									<small
										style={{
											color: '#9D9D9D',
											lineHeight: '12px',
											display: 'block'
										}}
									>
										{item.email}
									</small>
								</Box>
							</Grid>
							<Grid item md={3} textAlign={'right'}>
								<TooltipButton
									showTooltipWhenDisabled={item.email === loginUser.email}
									tooltipText={
										item.email === loginUser.email
											? 'Kendinizi Arayamazsınız'
											: item.status === 1
												? 'Kullanıcı Şuan Başka Bir Görüşmede'
												: ''
									}
									disabled={item.email === loginUser.email || item.status === 1}
									onClick={() => callUser(item)}
									color={'primary'}
									style={{
										borderRadius: '50%',
										width: '45px',
										height: '45px',
										minWidth: '45px'
									}}
									variant={'contained'}
								>
									<PhoneIcon/>
								</TooltipButton>
							</Grid>
						</Grid>
					))}
					
					{onlineUsers.length === 0 && (
						<Grid
							container
							direction={'row'}
							sx={{
								paddingTop: 2,
								paddingBottom: 2,
								borderBottom: '1px solid rgba(58,21,161,50%)',
								justifyContent: 'center'
							}}
						>
							{t('noOnlineUsers')}
						</Grid>
					)}
				</Grid>
			</Box>
		</Box>
	);
};

export default Persons;
