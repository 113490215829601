import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {useCookies} from 'react-cookie';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {InputAdornment, Modal} from '@mui/material';
import {AccountServices} from 'services/index';
import loginTypeEnum from 'enums/loginTypeEnum';
import ForgotPassword from 'views/authPages/ForgotPassword';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {handleAfterLoginStoreWorks, handleAfterLoginTokenWorks} from 'utils/loginHelpers';
import {useHistory, useLocation} from 'react-router-dom';
import {JSEncrypt} from 'jsencrypt';
import {useTranslation} from 'react-i18next';
import ChangePassword from '../../../../supportingPages/Profile/components/ChangePassword/ChangePassword';
import {LoadingButton} from '@mui/lab';
import {Email as EmailIcon, Lock as LockIcon} from '@mui/icons-material';

const Form = () => {
	let history = useHistory();
	const {t, i18n} = useTranslation();
	let location = useLocation();
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [isLoading, setIsLoading] = useState(false);
	const [cookies, setCookie] = useCookies(['token']);
	const [mustChangePassword, setMustChangePassword] = useState(false);
	
	const validationSchema = yup.object({
		email: yup
			.string()
			.trim()
			.email(t('validEmail'))
			.matches(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, t('validEmail'))
			.emailSyntaxCheck(t('validEmail'))
			.required(t('requiredField')),
		password: yup
			.string()
			.trim()
			.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/, t('validPasswordRegex'))
			.required(t('validPassword'))
			.min(8, t('validPasswordMin'))
	});
	
	const initialValues = {
		email: '',
		password: '',
		recaptcha: ''
	};
	
	useEffect(() => {
		formik.resetForm();
		return () => {
			formik.resetForm();
		};
	}, []);
	
	const onSubmit = (values) => {
		setIsLoading(true);
		let sentValues = {...values};
		sentValues.loginType = loginTypeEnum.StandAlone;
		signIn(sentValues);
	};
	
	const signIn = (data) => {
		var encrypt = new JSEncrypt();
		encrypt.setPublicKey(process.env.REACT_APP_PASSWORD_KEY);
		data.password = encrypt.encrypt(data.password);
		data.name = data.name ? data.name.trim() : '';
		data.surname = data.surname ? data.surname.trim() : '';
		delete data.recaptcha;
		var promise = AccountServices.login(data);
		promise
			.then((response) => {
				handleAfterLoginTokenWorks(response.data.token, setCookie);
				handleAfterLoginStoreWorks();
				if (response.data.mustChangePasswordNextLogon) {
					setMustChangePassword(response.data.mustChangePasswordNextLogon);
				} else {
					redirect();
				}
			})
			.catch(() => {
				data.password = '';
				setIsLoading(false);
			});
	};
	
	const redirect = () => {
		const query = new URLSearchParams(location.search);
		const q = query.get('redirectUrl');
		if (q) {
			setTimeout(() => {
				history.push(q);
			}, 1000);
		} else {
			history.push('/profile/meetings');
		}
	};
	
	const formik = useFormik({
		initialValues,
		validationSchema: validationSchema,
		onSubmit
	});
	
	return (
		<Box paddingBottom={3} sx={{padding: {xs: '0 10px 0 10px', md: '0'}}}>
			<Box marginBottom={4} textAlign={'left'}>
				<Typography
					variant="h5"
					color="text.tertiary"
					sx={{
						fontWeight: 700
					}}
				>
				
				</Typography>
				<Box sx={{fontSize: {xs: '12px', md: '14px'}, marginTop: '5px'}}>
          <span style={{color: '#424242', fontSize: '14px', fontWeight: 400}}>
          </span>
				</Box>
			</Box>
			
			<form onSubmit={formik.handleSubmit}>
				<Grid
					container spacing={4} direction="column"
					alignItems={{xs: 'stretched', sm: 'stretched'}}
					justifyContent="center"
				>
					<Grid item xs={12} md={6}>
						<TextField
							label={t('emailAdressRequired')}
							variant="outlined"
							name={'email'}
							fullWidth
							value={formik.values.email}
							onChange={formik.handleChange}
							error={formik.touched.email && Boolean(formik.errors.email)}
							helperText={formik.touched.email && formik.errors.email}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<EmailIcon/>
									</InputAdornment>
								),
								style: {backgroundColor: '#fff', borderRadius: '10px'}
							}}
							inputProps={{style: {textTransform: 'lowercase'}}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label={t('passwordRequired')}
							variant="outlined"
							name={'password'}
							type={'password'}
							fullWidth
							value={formik.values.password}
							onChange={formik.handleChange}
							error={formik.touched.password && Boolean(formik.errors.password)}
							helperText={formik.touched.password && formik.errors.password}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LockIcon/>
									</InputAdornment>
								),
								style: {backgroundColor: '#fff', borderRadius: '10px'}
							}}
						/>
						<Box
							display="flex"
							flexDirection={{xs: 'row', sm: 'row'}}
							alignItems={{xs: 'center', sm: 'center'}}
							justifyContent={'space-between'}
							width={'100%'}
							marginY={1}
						>
							<Typography variant={'subtitle2'}>
								<Link
									component={Button}
									color={'primary'}
									onClick={handleShow}
									underline={'none'}
								>
									{t('forgotPassword')}
								</Link>
								<Modal
									open={show}
									onClose={handleClose}
									aria-labelledby="modal-modal-title"
									aria-describedby="modal-modal-description"
								>
									<Box
										position={'relative'}
										backgroundColor={'white'}
										maxWidth={600}
										minWidth={'30%'}
										sx={{
											borderRadius: '20px',
											position: 'absolute',
											left: {xs: '10%', md: '50%'},
											top: {xs: '25%', md: '50%'},
											transform: {xs: 'translate(-5%, -25%)', md: 'translate(-50%, -50%)'},
											padding: '50px 0 50px 0'
										}}
									>
										<Box sx={{position: 'absolute', right: '0px', top: '15px'}}>
											<Button onClick={handleClose}><CloseRoundedIcon/></Button>
										</Box>
										<ForgotPassword/>
									</Box>
								</Modal>
							</Typography>
						</Box>
					</Grid>
					
					<Grid item container xs={12} md={6}>
						<Box
							display="block"
							style={{width: '100%'}}
						>
							<LoadingButton
								loading={isLoading}
								color={'primary'}
								size={'large'}
								variant={'contained'}
								type={'submit'}
								sx={{
									fontWeight: 500,
									padding: '13px',
									width: '100%',
									borderRadius: '10px',
									backgroundColor: '#f44336',
									'&:hover': {
										backgroundColor: '#d32f2f'
									}
								}}
							>
								{t('headerSignIn')}
							</LoadingButton>
						</Box>
					</Grid>
					<Grid
						item
						container
						xs={12}
						md={6}
						sx={{paddingTop: '10px !important', textAlign: 'center'}}
						justifyContent={'center'}
					>
						<Typography variant={'subtitle2'}>
							<Link
								component={Button}
								color={'primary'}
								onClick={() => {
									history.replace('/signup');
								}}
								underline={'none'}
							>
								{t('newRegist')}
							</Link>
						</Typography>
					</Grid>
				</Grid>
			</form>
			
			<Modal
				open={mustChangePassword}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					backgroundColor={'white'}
					maxWidth={700}
					minWidth={'20%'}
					sx={{
						position: 'absolute',
						left: {xs: '10%', md: '50%'},
						top: {xs: '25%', md: '50%'},
						transform: {xs: 'translate(-5%, -25%)', md: 'translate(-50%, -50%)'},
						borderRadius: 10
					}}
				>
					<Box sx={{alignContent: 'center', alignItems: 'center', justifyContent: 'center', padding: 5}}>
						<ChangePassword
							isLogin={true}
							onSuccess={redirect}
						/>
					</Box>
				</Box>
			</Modal>
		
		</Box>
	);
};

export default Form;
