import {Grid} from '@mui/material';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import React, {useEffect} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {AccountServices} from 'services';
import LogoS from 'svg/logos/LogoSiyah.svg';
import Form from './components/Form';
import PropTypes from 'prop-types';

const ChangePassword = () => {
	let location = useLocation();
	let history = useHistory();
	let vCode = location.search.substring(3, location.search.length);
	let accontVerificationUrl = '/account/password-verification-check?v=' + vCode;
	
	useEffect(() => {
		let promise = AccountServices.verifyAccount(accontVerificationUrl);
		
		promise
			.then((res) => {
				if (!res) {
					setTimeout(() => {
						history.push('/login');
					}, 500);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);
	
	return (
		<Grid container height={'100vh'}>
			<Grid item md={3} sx={{backgroundColor: '#ECEBEE'}}/>
			<Grid item md={6}>
				<Box>
					<Container maxWidth={{xs: '100%', md: 600}} marginTop={5}>
						<Box>
							<Box
								to="/"
								component={Link}
								display={'flex'}
								justifyContent={'center'}
								marginLeft={'auto'}
								marginRight={'auto'}
								marginBottom={10}
								paddingTop={5}
								sx={{
									height: {md: '59px', xs: '40px'},
									width: {md: '188px', xs: '170px'}
								}}
							>
								<img
									style={{maxWidth: '188px', height: '61px'}}
									src={LogoS}
								/>
							</Box>
						</Box>
						<Form/>
					</Container>
				</Box>
			</Grid>
			<Grid item md={3} sx={{backgroundColor: '#ECEBEE'}}/>
		</Grid>
	);
};

ChangePassword.propTypes = {
	match: PropTypes.object
};

export default ChangePassword;
