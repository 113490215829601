import React, {useState} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const AddUserDialog = ({open, onClose, onSave}) => {
    const {t} = useTranslation();
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData((prev) => ({...prev, [name]: value}));
    };

    const handleSave = () => {
        if (formData.fullName && formData.email && formData.phoneNumber) {
            onSave(formData);  // Yeni kullanıcıyı kaydet
            setFormData({fullName: '', email: '', phoneNumber: ''});  // Formu temizle
            onClose(); // Save işleminden sonra diyalog kutusunu kapat
        } else {
            alert('Please fill in all fields.');  // Alanlar eksikse uyarı
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('add')}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="fullName"
                    label={t('fullName')}
                    type="text"
                    fullWidth
                    value={formData.fullName}
                    onChange={handleInputChange}
                    required
                />
                <TextField
                    margin="dense"
                    name="email"
                    label={t('email')}
                    type="email"
                    fullWidth
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                />
                <TextField
                    margin="dense"
                    name="phoneNumber"
                    label={t('phone')}
                    type="text"
                    fullWidth
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('cancel')}
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    {t('save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// PropTypes'ı kullanarak props'ları tanımlıyoruz
AddUserDialog.propTypes = {
    open: PropTypes.bool.isRequired,        // open prop'u boolean olmalı
    onClose: PropTypes.func.isRequired,     // onClose prop'u bir fonksiyon olmalı
    onSave: PropTypes.func.isRequired,      // onSave prop'u bir fonksiyon olmalı
};

export default AddUserDialog;
