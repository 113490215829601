import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Box, Button, Grid, IconButton} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import TextField from '@mui/material/TextField';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {useTranslation} from 'react-i18next';
import {languageFormat} from '../../../../../../utils/language';
import {useSelector} from 'react-redux';
import Swal from 'sweetalert2';
import {useHistory} from 'react-router-dom';

function HeaderButtons(
	{
		calenderViewType,
		setCalenderViewType,
		moveNextOrPrev,
		setCreateModalVisible,
		setPlanModalVisible,
		setCalenderDate,
		calenderDate,
		rangeStart,
		rangeEnd
	}) {
	const { t, i18n } = useTranslation();
	const [open, setOpen] = React.useState(false);
	const currentProduct = useSelector((state) => state.main.currentProduct);
	const history = useHistory(); // history hook'unu kullan
	
	useEffect(() => {
		setOpen(false);
	}, [calenderDate]);
	
	const handleNewMeetingPlanClick = () => {
		if (currentProduct.title === 'Default') {
			Swal.fire({
				title: t('warningPaymentCancellation'),
				text: t('defaultPackageMeetingPlanWarning'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: t('yes'),
				cancelButtonText: t('no'),
			}).then((result) => {
				if (result.isConfirmed) {
					history.push('/pricing');
				}
			});
		} else {
			setPlanModalVisible(true);
			setOpen(false);
		}
	};
	
	return (
		<div>
			<Grid container spacing={2} style={{direction: 'ltr'}}>
				<Grid
					item
					container
					xs={12}
					sm={6}
					md={7}
					direction={'row'}
					sx={{justifyContent: {md: 'flex-start', xs: 'center'}}}
					alignItems={'center'}
					pb={1}
				>
					<Box sx={{display: 'flex', alignItems: 'center'}}>
						<div>
							<Button
								onClick={() => setCalenderDate(new Date())}
								variant="text"
								sx={{fontWeight: 500, color: '#000'}}
							>
								{t('today')}
							</Button>
						</div>
						<div>
							<IconButton
								sx={{p: {xs: 0, sm: 1}}}
								onClick={() => moveNextOrPrev(-1)}
							>
								<ChevronLeftIcon/>
							</IconButton>
							<IconButton
								sx={{p: {xs: 0, sm: 1}}}
								onClick={() => moveNextOrPrev(1)}
							>
								<ChevronRightIcon/>
							</IconButton>
						</div>
						<Box sx={{paddingLeft: {xs: '10px', md: '0'}}}>
							<FormControl
								sx={{cursor: 'pointer', width: {md: 190, xs: 170}}}
							>
								{
									<div
										style={{
											display: open ? 'block' : 'none',
											position: 'fixed',
											left: 0,
											right: 0,
											top: 0,
											bottom: 0,
											zIndex: 1
										}}
										onClick={() => {
											setOpen(false);
										}}>
									</div>
								}
								<div onClick={() => {
									setOpen(true);
								}}>
									<DesktopDatePicker
										open={open}
										views={calenderViewType == 'month' ? ['month'] : ['day']}
										value={calenderDate}
										onChange={(value) => setCalenderDate(value)}
										onAccept={() => setOpen(false)}
										onYearChange={() => setOpen(false)}
										renderInput={(params) => {
											if (calenderViewType == 'day') {
												params.inputProps.value = new Date(calenderDate).toLocaleString(languageFormat(i18n.language), {
													day: 'numeric',
													month: 'long',
													year: 'numeric'
												});
											}
											
											if (calenderViewType === 'month') {
												params.inputProps.value = new Date(calenderDate).toLocaleString(languageFormat(i18n.language), {
													month: 'long',
													year: 'numeric'
												});
											}
											
											if (calenderViewType == 'week') {
												let rangeStrMonth = new Date(rangeStart).toLocaleString(languageFormat(i18n.language), {month: 'short'});
												let rangeEdMonth = new Date(rangeEnd).toLocaleString(languageFormat(i18n.language), {month: 'short'});
												
												let txt = new Date(rangeStart).getDate() + '.' + rangeStrMonth;
												txt += ' - ' + new Date(rangeEnd).getDate() + '.' + rangeEdMonth;
												params.inputProps.value = txt;
											}
											
											return (
												<>
													<TextField
														{...params}
														size="small"
														sx={{pointerEvents: 'none'}}
													/>
												</>
											);
										}}>
									</DesktopDatePicker>
								</div>
							</FormControl>
						</Box>
					</Box>
				</Grid>
				
				<Grid
					item
					container
					xs={12}
					sm={6}
					md={5}
					direction={'row'}
					sx={{justifyContent: {md: 'flex-end', xs: 'center'}}}
					alignItems={'center'}
					pb={1}
				>
					<div>
						<Button
							onClick={handleNewMeetingPlanClick}
							variant="outlined"
							sx={{
								fontWeight: 600,
								marginRight: '10px'
							}}
						>
							{t('newMeetingPlan')}
						</Button>
						<Button
							onClick={() => {
								setCreateModalVisible(true);
								setOpen(false);
							}}
							variant="contained"
							sx={{
								background: 'linear-gradient(to right, #d32f2f, #f44336, #ff5252) !important',
								fontWeight: 600,
								marginRight: '10px'
							}}
						>
							{t('newMeeting')}
						</Button>
					</div>
					<FormControl sx={{display: 'none', width: {xs: 140, sm: 110, md: 115}}}>
						<Select
							size={'small'}
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={calenderViewType}
							label="Gösterim Şekli"
							sx={{fontWeight: 500}}
							onChange={e => {
								setCalenderViewType(e.target.value);
								setOpen(false);
							}}
						>
							<MenuItem value={'day'}>{t('packetsDaily')}</MenuItem>
							<MenuItem value={'week'}>{t('packetsWeekly')}</MenuItem>
							<MenuItem value={'month'}>{t('packetsMonthly')}</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Grid>
		</div>
	);
}

HeaderButtons.propTypes = {
	calenderViewType: PropTypes.string,
	setCalenderViewType: PropTypes.func,
	moveNextOrPrev: PropTypes.func,
	setCreateModalVisible: PropTypes.func,
	setPlanModalVisible: PropTypes.func,
	setCalenderDate: PropTypes.func,
	calenderDate: PropTypes.object,
	rangeStart: PropTypes.object,
	rangeEnd: PropTypes.object
};

export default HeaderButtons;
