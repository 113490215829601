import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {AccountServices} from 'services';
import LogoBlue from 'svg/logos/LogoBlue.svg';
import Swal from 'sweetalert2';
import {Form} from './components';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from 'common/Container';
import {useSelector} from 'react-redux';

const Login = (props) => {
	const {t, i18n} = useTranslation();
	let history = useHistory();
	let location = useLocation();
	const query = new URLSearchParams(location.search);
	const accountverification = query.get('v');
	let accontVerificationUrl = `/account/account-verification?V=${accountverification}&NotMe=false`;
	const isAuthenticated = useSelector(state => state.main.isAuthenticated);
	
	useEffect(() => {
		if (accountverification) {
			let promise = AccountServices.verifyAccount(accontVerificationUrl);
			promise
				.then((res) => {
					if (res) {
						let timerInterval;
						Swal.fire({
							icon: 'success',
							title: t('success'),
							timer: 4000,
							timerProgressBar: true,
							showCloseButton: false,
							showConfirmButton: false,
							showDenyButton: false,
							text: t('emailValidateOK'),
							willClose: () => {
								clearInterval(timerInterval);
							}
						}).then((result) => {
							if (result.dismiss === Swal.DismissReason.timer) {
								history.push('/login');
							}
						});
					}
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}, []);
	
	if (isAuthenticated) {
		history.push('/profile/meetings');
	}
	
	return (
		<div style={{backgroundColor: '#FFFFFF', minHeight: '100vh'}}>
			
			<Grid
				container
				minHeight={'calc(100vh)'}
				sx={{backgroundColor: '#FFFFFF'}} // Set the background color of the main container to white
			>
				<Grid
					item
					container
					alignItems={'center'}
					justifyContent={'center'}
					xs={12}
					md={12}
				>
					<Box display={'flex'} flexGrow={'1'} alignItems={'center'} height={'100%'}>
						<Container maxWidth={'440px'}>
							<Box sx={{display: {xs: 'block', md: 'none'}, textAlign: 'center', padding: '40px 0 20px 0'}}>
								<img style={{width: '167px'}} src={LogoBlue} alt="Logo"/>
							</Box>
							<Form/>
						</Container>
					</Box>
				</Grid>
			</Grid>
		
		</div>
	);
};

Login.propTypes = {
	match: PropTypes.object
};

export default Login;
