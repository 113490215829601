import React from 'react';
import {Box, List, ListItem} from '@mui/material';
import {useTranslation} from 'react-i18next';

const languages = [
	{code: 'tr', name: 'Türkçe'},
	{code: 'en', name: 'English'},
	{code: 'ar', name: 'العربية'},
	{code: 'ru', name: 'Русский'}
];

const LanguageSwitcher = () => {
	const {t, i18n} = useTranslation();
	
	function changeLang(lang) {
		i18n.changeLanguage(lang);
		localStorage.setItem('lngChnged', true);
	}
	
	const options = languages.map((language, i) => {
		if (language.code !== i18n.language) {
			return (
				<ListItem
					sx={{
						paddingLeft: '7px',
						paddingRight: '7px',
						textAlign: 'left',
						width: '100%',
						justifyContent: 'left',
						paddingTop: 2,
						paddingBottom: 0,
						color: '#b8c0c0'
					}}
					key={i}
					onClick={() => changeLang(language.code)}
				>
					<Box>
						<span style={{borderRadius: '100%', fontSize: '12px'}}>{language.name}</span>
					</Box>
				</ListItem>
			);
		}
		return null;
	});
	
	return (
		<Box
			sx={{
				position: 'relative',
				cursor: 'pointer',
				'& .MuiBox-root.MuiBox-root': {
					display: 'block'
				},
				'& .MuiList-root': {
					transition: 'all 1s ease-in-out'
				},
				'&:hover': {
					'& .MuiList-root': {
						opacity: 1,
						visibility: 'visible'
					}
				}
			}}
		>
			<Box
				display={'flex'}
				justifyContent={'center'}
				alignItems={'center'}
				className="targetBox"
				sx={{
					borderRadius: '10px !important',
					fontSize: '14px',
					color: '#b8c0c0',
					position: 'relative',
					fontWeight: '500',
					paddingLeft: '3px',
					'&:after': {
						content: '""',
						position: 'absolute',
						right: '10px',
						top: '50%',
						transform: 'translateY(-50%)',
						width: 0,
						height: 0,
						borderLeft: '6px solid transparent',
						borderRight: '6px solid transparent',
						borderTop: '6px solid #b8c0c0'
					}
				}}
			>
				<Box
					width={100}
					height={50}
					alignItems={'center'}
					style={{
						display: 'flex',
						justifyContent: i18n.language === 'ar' ? 'left' : null
					}}
				>
          <span style={{fontSize: '14px'}}>
            {languages.find(lang => lang.code === i18n.language)?.name}
          </span>
				</Box>
			</Box>
			<List
				sx={{
					backgroundColor: 'white !important',
					top: '50px !important',
					borderBottomLeftRadius: '10px !important',
					borderBottomRightRadius: '10px !important',
					position: 'absolute',
					opacity: 0,
					visibility: 'hidden',
					margin: '0',
					padding: '0',
					listStyle: 'none',
					fontSize: '10px',
					color: '#b8c0c0',
					fontWeight: '700',
					width: '80px'
				}}
			>
				{options}
			</List>
		</Box>
	);
};

export default LanguageSwitcher;
