import React, {useEffect, useRef, useState} from 'react';
import {ErrorMessage, Formik} from 'formik';
import * as yup from 'yup';
import 'yup-phone';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {UserServices} from 'services';
import PhoneInput from 'react-phone-input-2';
import {ulkeler} from '../../../Pricing/components/ulkeler';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {il_ilce} from '../../../Pricing/components/il-ilce';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import store from '../../../../../redux/store';
import {setUser} from '../../../../../redux/actions';
import {useSelector} from 'react-redux';
import 'react-phone-input-2/lib/material.css';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {Avatar} from '@mui/material';
import {FiInfo} from 'react-icons/fi';
import ChangePassword from '../ChangePassword/ChangePassword';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

yup.addMethod(yup.date, 'format', function (formats, parseStrict) {
	return this.transform(function (value, originalValue) {
		value = moment(originalValue, formats, parseStrict);
		return value.isValid() ? value.toDate() : new Date('');
	});
});

yup.addMethod(yup.string, 'tcNo', function (errorMessage) {
	return this.test('test-tcNo', errorMessage, function (value) {
		const {path, createError} = this;
		let TCNO = value;
		var tek = 0,
			cift = 0,
			sonuc = 0,
			i = 0,
			TCToplam = 0,
			hatali = [11111111110, 22222222220, 33333333330, 44444444440, 55555555550, 66666666660, 7777777770, 88888888880, 99999999990];
		
		if (TCNO?.length != 11) return false;
		if (isNaN(TCNO)) return false;
		if (TCNO[0] == 0) return false;
		
		tek = parseInt(TCNO[0]) + parseInt(TCNO[2]) + parseInt(TCNO[4]) + parseInt(TCNO[6]) + parseInt(TCNO[8]);
		cift = parseInt(TCNO[1]) + parseInt(TCNO[3]) + parseInt(TCNO[5]) + parseInt(TCNO[7]);
		
		tek = tek * 7;
		sonuc = Math.abs(tek - cift);
		if (sonuc % 10 != TCNO[9]) return false;
		
		for (i = 0; i < 10; i++) {
			TCToplam += parseInt(TCNO[i]);
		}
		
		if (TCToplam % 10 != TCNO[10]) return false;
		
		if (hatali.toString().indexOf(TCNO) != -1) return false;
		
		return (true || createError({path, message: errorMessage}));
	});
});

const ProfileEdit = () => {
	const {t, i18n} = useTranslation();
	const formikRef = useRef();
	// eslint-disable-next-line no-unused-vars
	const [ulke, setUlke] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [items, setItems] = useState({
		name: '',
		surname: '',
		email: '',
		identityNumber: '',
		phoneNumber: '',
		dateOfBirth: '',
		country: '',
		city: '',
		registrationAddress: ''
	});
	// eslint-disable-next-line no-unused-vars
	const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
	const [isAdressCanBeNullable, setIsAdressCanBeNullable] = useState(null);
	const [enabled, setEnabled] = useState(false);
	const user = useSelector((state) => state.main.user);
	
	const validationSchema = yup.object({
		name: yup
			.string(t('requiredField'))
			.trim()
			.min(2, t('validName'))
			.max(50, t('validName'))
			.required(t('validName'))
			.matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u, t('validName')),
		surname: yup
			.string(t('validSurname'))
			.trim()
			.matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u, t('validSurname'))
			.min(2, t('validSurname'))
			.max(50, t('validSurname'))
			.required(t('requiredField')),
		phoneNumber: yup
			.string()
			.phone(null, null, t('validPhoneNumber'))
			.required(t('requiredField')),
		dateOfBirth: yup
			.date(t('validDateOfBirth'))
			.min(new Date('1900-01-01'), t('validDateOfBirth'))
			.max(new Date(), t('validDateOfBirth'))
			.required(t('requiredField')),
		email: yup
			.string(t('validEmail'))
			.trim()
			.email(t('validEmail'))
			.required(t('requiredField')),
		registrationAddress: yup
			.string()
			.min(10, t('validAddress'))
			.when('city', {
				is: value => value && value !== '',
				then: isAdressCanBeNullable ? yup.string() : yup.string().required(t('requiredField'))
			}),
		identityNumber: yup
			.string(t('validIdentityNumber'))
			.min(11, t('validIdentityNumber'))
			.max(11, t('validIdentityNumber'))
			.tcNo(t('validIdentityNumber'))
			.notRequired()
	});
	
	let initialValues = {
		name: '',
		surname: '',
		email: '',
		identityNumber: '',
		phoneNumber: '',
		dateOfBirth: '',
		country: '',
		city: '',
		registrationAddress: ''
	};
	
	const editUser = (values) => {
		let newValues = {
			...values,
			loginType: 1,
			isAdressCanBeNullable,
			dateOfBirth: values.dateOfBirth ? new Date(values.dateOfBirth).toISOString() : null
		};
		if (typeof newValues.registrationAddress === 'string' && newValues.registrationAddress.length === 0) {
			newValues.registrationAddress = null;
		}
		let promiseEditUser = UserServices.editUser(newValues);
		promiseEditUser
			.then((response) => {
				
				if (response) {
					setEnabled(false);
					let newUser = {...user};
					newUser.name = values.name;
					store.dispatch(setUser(newUser));
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};
	
	const getUserProfile = () => {
		UserServices.getUserProfileInformation().then((response) => {
			if (response) {
				let result = response.data;
				initialValues = {
					name: result.name ? result.name : '',
					surname: result.surname ? result.surname : '',
					email: result.email ? result.email : '',
					identityNumber: result.identityNumber ? result.identityNumber : '',
					phoneNumber: result.phoneNumber ? result.phoneNumber : '',
					dateOfBirth: result.dateOfBirth ? result.dateOfBirth : '',
					country: result.country ? result.country : '',
					city: result.city ? result.city : '',
					registrationAddress: result.registrationAddress ? result.registrationAddress : ''
				};
				setIsAdressCanBeNullable(result.isAdressCanBeNullable);
				formikRef?.current?.setValues(initialValues);
				setItems(result);
			}
		});
	};
	
	useEffect(() => {
		getUserProfile();
	}, []);
	
	const onSubmit = (values) => {
		editUser(values);
	};
	
	const onCancel = () => {
		setEnabled(!enabled);
	};
	
	return (
		<Box>
			<Box marginBottom={5}>
				<Typography
					variant="h5"
					color="text.tertiary"
					sx={{
						fontWeight: 700,
						textAlign: 'start'
					}}
				>
					{t('editProfile')}
				</Typography>
			</Box>
			<Box style={{display: 'flex'}} marginBottom={5}>
				<Box style={{flex: 1}}>
					<Box
						display={'flex'}
						minWidth={185}
						padding={'8px'}
						className="targetBox"
						sx={{
							alignItems: 'center',
							borderRadius: '10px !important',
							fontSize: '0.875rem',
							direction: i18n.language === 'ar' ? 'initial' : null
						}}
					>
						<Box sx={{
							borderRadius: '5px !important'
						}}>
							<Avatar src="/broken-image.jpg" sx={{height: 45, width: 45, borderRadius: '10px'}}/>
						</Box>
						<Box paddingLeft={'10px'} flex={1} overflow={'hidden'} sx={{
							position: 'relative'
						}}>
							<div style={{
								color: '#424242',
								position: 'relative',
								fontWeight: 600
							}}>
								<div style={{lineHeight: '0.875rem', fontSize: '17px', marginBottom: '2px'}}>
									{user.name} {user.surname}
								</div>
								{/*  ÜNVAN ALANI
                 <div style={{lineHeight: '0.875rem', marginTop:'4px',   fontWeight: 400,}}>
                     <small>UI/UX Designer</small>
                 </div>
                 */}
							</div>
						</Box>
					</Box>
				</Box>
				{/* Profil fotografı düzenleme
        <Box sx={{width:{xs:'auto',md:'230px', marginTop:'7px'}}}>
         <AvatarChange/>
        </Box>
         */}
			</Box>
			<Formik
				innerRef={formikRef}
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}>
				{({errors, touched, values, handleChange, handleSubmit}) => {
					
					let disableCity = values.country && !enabled == false ? false : true;
					let disableAdress = values.city && !enabled == false ? false : true;
					
					return (
						<form onSubmit={handleSubmit}>
							
							<Grid container spacing={3} xl={12}>
								<Grid item xs={12} sm={6}>
									<TextField
										fullWidth
										variant="outlined"
										disabled={!enabled}
										name={'identityNumber'}
										onChange={handleChange}
										value={values.identityNumber}
										label={t('profileIdentityNumber')}
										onInput={(e) => {
											const value = e.target.value;
											const length = value.length;
											if (length > 11) {
												e.target.value = value.toString().slice(0, 11);
											}
											const reg = new RegExp('^[0-9]*$');
											if ((!reg.test(value))) {
												e.target.value = value.toString().slice(0, value.length - 1);
											}
										}}
										helperText={touched.identityNumber && errors.identityNumber}
										error={touched.identityNumber && Boolean(errors.identityNumber)}
									/>
								</Grid>
								<Grid item container xs={12} sm={6}>
									
									<div style={{display: 'flex', color: '#424242'}}>
										<FiInfo fontSize={'24px'}/>
										<span style={{fontSize: '14px', marginLeft: '5px', fontWeight: '400'}}>Satın alma işleminizi faturalandırmak için vergi numaranız veya kimlik numaranız gerekmektedir.</span>
									</div>
								</Grid>
								
								<Grid item container xs={12} sm={6}>
									<TextField
										label={t('registerFirstname')}
										className={'outlined'}
										variant="outlined"
										name={'name'}
										disabled={!enabled}
										fullWidth
										value={values.name}
										onChange={handleChange}
										error={
											touched.name && Boolean(errors.name)
										}
										helperText={touched.name && errors.name}
									/>
								</Grid>
								<Grid item container xs={12} sm={6}>
									<TextField
										label={t('registerLastname')}
										variant="outlined"
										name={'surname'}
										disabled={!enabled}
										fullWidth
										value={values.surname}
										onChange={handleChange}
										error={touched.surname && Boolean(errors.surname)}
										helperText={touched.surname && errors.surname}
									/>
								</Grid>
								<Grid item container xs={12} sm={6}>
									<TextField
										label={t('registerEmail')}
										variant="outlined"
										name={'email'}
										disabled
										fullWidth
										value={values.email}
										onChange={handleChange}
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
									/>
								</Grid>
								<Grid item container xs={12} sm={6}>
									
									<PhoneInput
										containerClass={!enabled ? 'active' : 'disable'}
										specialLabel={t('profilePhone')}
										disabled={!enabled}
										country={'tr'}
										preferredCountries={['tr']}
										name={'phoneNumber'}
										value={values.phoneNumber}
										onChange={(phoneNumber, country, e) => {
											handleChange(e);
										}}
										containerStyle={{direction: 'ltr'}}
										inputProps={{name: 'phoneNumber'}}
										dropdownStyle={{'& .special-label': {backgroundColor: '#fff'}}}
										buttonStyle={{
											borderRadius: '10px 0 0 10px',
											border: '1px solid #424242',
											borderRight: 0,
											backgroundColor: '#fff'
										}}
										inputStyle={{
											borderRadius: '10px',
											width: '100%',
											border: '1px solid #424242',
											backgroundColor: '#fff',
											height: 60,
											color: !enabled ? '#424242' : '#424242'
										}}
									/>
									{
										touched.phoneNumber && <ErrorMessage name="phoneNumber">{msg => <p style={{
											fontSize: '0.75rem',
											lineHeight: '1.66',
											marginTop: '3px',
											color: '#d32f2f'
										}}>
											<InfoIcon
												style={{
													width: '17px',
													height: '17px',
													marginRight: '5px',
													verticalAlign: 'middle'
												}}/>
											<span style={{lineHeight: '17px', verticalAlign: 'middle'}}>{msg}</span>
										</p>}
										</ErrorMessage>
									}
								
								</Grid>
								<Grid item container xs={12} sm={3}>
									<FormControl fullWidth>
										<InputLabel
											style={{
												marginLeft: 10,
												marginTop: -5,
												zIndex: 1,
												color: '#424242',
												backgroundColor: '#fff',
												padding: '0 7px 0 7px'
											}}
											variant="standard"
											htmlFor="country-html">
											{t('profileCountry')}
										</InputLabel>
										<Select
											fullWidth
											value={values.country}
											variant="outlined"
											name={'country'}
											inputProps={{
												id: 'country-html'
											}}
											disabled={!enabled}
											onChange={(event) => {
												setUlke(event.target.value);
												formikRef.current.setFieldValue('city', '');
												handleChange(event);
											}}
											error={touched.country && Boolean(errors.country)}
										>
											{ulkeler.map((item, i) => (
												<MenuItem value={item} key={i}>{item}</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item container xs={12} sm={3}>
									{
										values.country === 'Türkiye' ?
											
											<FormControl fullWidth>
												<InputLabel
													style={{marginLeft: 15, marginTop: -5, color: !enabled && '#00000061'}}
													variant="standard"
													htmlFor="city-html">
													{t('profileCity')}
												</InputLabel>
												<Select
													name={'city'}
													variant="filled"
													fullWidth
													inputProps={{
														id: 'city-html'
													}}
													disabled={disableCity}
													value={values.city}
													onChange={handleChange}
													error={touched.city && Boolean(errors.city)}
												>
													{values.country == 'Türkiye' ? il_ilce.map((item, i) => (
														<MenuItem value={item.il} key={i}>{item.il}</MenuItem>
													)) : <div></div>}
												</Select>
											</FormControl>
											
											: <TextField
												label={t('profileCity')}
												autoComplete="false"
												variant="outlined"
												fullWidth
												name={'city'}
												value={values.city}
												disabled={disableCity}
												onChange={handleChange}
												error={touched.city && Boolean(errors.city)}
											/>
									}
								</Grid>
								<Grid item container xs={12} sm={6}>
									<DesktopDatePicker
										disableFuture
										disabled={!enabled}
										name={'dateOfBirth'}
										label={t('profileDateOfBirth')}
										inputFormat="dd.MM.yyyy"
										value={values.dateOfBirth}
										onChange={(e) => {
											if (e) {
												formikRef.current.setFieldValue('dateOfBirth', new Date(e));
											} else {
												formikRef.current.setFieldValue('dateOfBirth', null);
											}
										}}
										renderInput={(params) =>
											<TextField
												{...params}
												fullWidth
												style={{direction: 'ltr'}}
												onInput={(e) => {
													const value = e.target.value;
													const length = value.length;
													if (length > 10) {
														e.target.value = value.toString().slice(0, 10);
													}
												}}
												error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
												helperText={touched.dateOfBirth && errors.dateOfBirth && t('validDateOfBirth')}
											/>
										}
									/>
								</Grid>
								<Grid item container xs={12} sm={12}>
									<TextField
										label={t('profileAddress')}
										variant="outlined"
										name={'registrationAddress'}
										disabled={disableAdress}
										fullWidth
										multiline
										rows={4}
										value={values.registrationAddress}
										onChange={handleChange}
										error={touched.registrationAddress && Boolean(errors.registrationAddress)}
										helperText={touched.registrationAddress && errors.registrationAddress}
									/>
								</Grid>
								<Grid item container xs={12}>
									<Box
										display="flex"
										alignItems={{xs: 'stretched', sm: 'center'}}
										justifyContent={'end'}
										width={'100%'}
									>
										<Button
											size={'large'} variant={'outlined'} onClick={() => setOpenChangePasswordModal(true)}
											sx={{width: 200, marginRight: 1}}>
											{t('profileChangePassword')}
										</Button>
										
										{!enabled ?
											<Button
												size={'large'} variant={'outlined'} onClick={() => setEnabled(!enabled)}
												sx={{width: 160}}>
												{t('edit')}
											</Button> :
											
											<Grid container item xs={12} justifyContent={'right'}>
												<Grid
													textAlign={'right'}
													item
													container
													md={6}
													xs={12}
													justifyContent={'right'}
												>
													<Button
														size={'large'}
														variant={'outlined'}
														onClick={() => onCancel()}
														sx={{
															marginRight: 2,
															marginLeft: i18n.language === 'ar' ? 2 : 0,
															minWidth: '100px',
															width: '40%',
															maxWidth: '160px'
														}}>
														{t('cancel')}
													</Button>
													<Button
														size={'large'}
														variant={'contained'}
														type={'submit'}
														sx={{minWidth: '100px', width: '40%', maxWidth: '160px'}}>
														{t('save')}
													</Button>
												</Grid>
											</Grid>
										}
									</Box>
								</Grid>
							</Grid>
						</form>
					);
				}}
			</Formik>
			
			<Dialog open={openChangePasswordModal} onClose={() => setOpenChangePasswordModal(false)} maxWidth="sm" fullWidth>
				<DialogContent>
					<ChangePassword
						onSuccess={() => setOpenChangePasswordModal(false)}
					/>
				</DialogContent>
			</Dialog>
		</Box>
	);
};

export default ProfileEdit;
