import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LogoutIcon from '@mui/icons-material/Logout';
import Group from '@mui/icons-material/Group';
import {List} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import CustomListItem from './components/CustomListItem/CustomListItem';
import Meetings from './components/Meetings/Meetings';
import ProfileEdit from './components/ProfileEdit/ProfileEdit';
import Addressbook from './components/Addressbook/Addressbook';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import PastMeetings from './components/PastMeetings/PastMeetings';
import Persons from './components/Persons/Persons';
import Calls from './components/Calls/Calls';
import ReplyIcon from '@mui/icons-material/Reply';
import {Phone} from '@mui/icons-material';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ModalWithTabs from './components/ModalWithTabs';

const Profile = ({match: {params: {title}}}) => {
	const {t, i18n} = useTranslation();
	const theme = useTheme();
	const currentProduct = useSelector((state) => state.main.currentProduct);
	const [navArr, setNavArr] = useState([]);
	const history = useHistory();
	
	useEffect(() => {
		const menuItems = [
			{
				page: 'profile',
				title: t('profile'),
				icon: <AccountCircleIcon/>
			},
			{
				page: 'meetings',
				title: t('meetings'),
				icon: <EventAvailableIcon/>
			},
			...(currentProduct.title === 'Enterprise' ? [
				{
					page: 'addressbook',
					title: t('addressBook'),
					icon: <Group/>
				},
				{
					page: 'persons',
					title: t('persons'),
					icon: <Group/>
				},
				{
					page: 'calls',
					title: t('calls'),
					icon: <Phone/>
				}
			] : []),
			{
				page: 'pastMeetings',
				title: t('myPastMeeting'),
				icon: <ReplyIcon/>
			},
			{
				page: 'logout',
				title: t('logout'),
				icon: <LogoutIcon/>
			}
		];
		
		setNavArr(menuItems);
	}, [i18n.language, currentProduct.title, t]);
	
	const renderSwitch = (title) => {
		switch (title) {
			case 'meet':
				return <ModalWithTabs/>;
			case 'profile':
				return <ProfileEdit/>;
			case 'persons':
				return currentProduct.title === 'Enterprise' ? <Persons/> : history.push('/');
			case 'calls':
				return currentProduct.title === 'Enterprise' ? <Calls/> : history.push('/');
			case 'meetings':
				return <Meetings/>;
			case 'addressbook':
				return currentProduct.title === 'Enterprise' ? <Addressbook/> : history.push('/');
			case 'pastMeetings':
				return <PastMeetings/>;
			default:
				return <ProfileEdit/>;
		}
	};
	
	const isMd = useMediaQuery(theme.breakpoints.up('xl'), {
		defaultMatches: true
	});
	
	return (
		<>
			<Grid sx={{position: 'relative', zIndex: 2}} container>
				<Grid item md={2} sx={{display: {xl: 'block', md: 'none', backgroundColor: '#F9FBFF'}}}/>
				<Grid item md={isMd ? 8 : 12}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							minHeight: 'calc(100vh - 160px)',
							backgroundColor: '#fff'
						}}
					>
						<Box
							sx={{
								width: 330,
								backgroundColor: '#F9FBFF',
								display: {xs: 'none', md: 'block'},
								padding: '50px 30px 0 30px'
							}}
						>
							<List component="nav">
								{navArr.map((item, i) => (
									<Box key={i}>
										<CustomListItem
											to={'/profile/' + item.page}
											title={item.title}
											icon={item.icon}
											isSelected={title == item.page}
											isExit={item.page == 'logout'}
										/>
									</Box>
								))}
							</List>
						</Box>
						
						<Box paddingY={{md: 7, xs: 7}} paddingX={{md: 7, xs: 2}} width={'100%'}>
							{renderSwitch(title)}
						</Box>
					</Box>
				</Grid>
				<Grid item md={2} sx={{display: {xl: 'block', md: 'none'}}}/>
			</Grid>
		</>
	);
};

Profile.propTypes = {
	match: PropTypes.object
};

export default Profile;
